<template>
  <BContainer class="vw-privacy-obtaining mt-4">
    <BCard>
      <OCardHeader slot="header">{{ $t(`${prefix}.headline-download`) }}</OCardHeader>
      <a :href="importConditionProps.link" :download="importConditionProps.filename">
        {{ $t(`${prefix}.download-link`) }}
      </a>
    </BCard>
    <h3 class="text-center mb-3">{{ $t(`${prefix}.line-breaker`) }}</h3>
    <BCard>
      <OCardHeader slot="header">{{ $t(`${prefix}.headline-form`) }}</OCardHeader>
      <CModelPerson :disabled="isSubmitted" v-model="$v.model.$model">
        <template slot="after-content">
          <OInputValue
            required
            type="email"
            :disabled="isSubmitted"
            icon-name="envelope"
            identity="obtaining-input-email"
            :label="$t('fields.email')"
            :error="getErrorMessage($v.email, $t('validation-errors.email'))"
            v-model="$v.email.$model"
          />
        </template>
      </CModelPerson>
      <div slot="footer" class="text-right">
        <BButton v-if="isSubmitted" variant="dark" @click="onClickReset">{{ $t(`${prefix}.reset-label`) }}</BButton>
        <BButton v-else variant="primary" :disabled="canSubmit" @click="onClickSubmit">{{
          $t(`${prefix}.submit-label`)
        }}</BButton>
      </div>
    </BCard>
  </BContainer>
</template>
<script>
import { BContainer, BCard, BButton } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import OInputValue from '../../o/input/value';
import OCardHeader from '../../o/card/header';
import CardButtons from '../../o/card/buttons';
import CModelPerson from '../../c/model/person';

const prefix = 'vw.privacy-obtaining';

export default {
  mixins: [pageMixin, validationMixin, serviceMixin],
  components: {
    BContainer,
    BCard,
    OCardHeader,
    CardButtons,
    OInputValue,
    CModelPerson,
    BButton,
  },
  data() {
    return {
      model: {},
      email: null,
      invalidPerson: false,
    };
  },
  computed: {
    prefix: () => prefix,
    canSubmit() {
      return this.$v.$invalid;
    },
    importConditionProps() {
      return this.$t(`vw.my-download.links.importCondition`);
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    model: {
      salutation: {
        required,
      },
      lastname: {
        required,
      },
      firstname: {
        required,
      },
    },
  },
  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },
  methods: {
    /**
     * get error message if invalid and input invalid
     * @info use vuelidate
     *
     * @param validator
     * @param errorMessage
     * @returns {null|*}
     */
    getErrorMessage(validator, errorMessage) {
      if (validator.$invalid && validator.$dirty) {
        return errorMessage;
      }

      return null;
    },

    onClickReset() {
      this.model = {};
      this.email = null;
      this.isSubmitted = false;
    },

    onClickSubmit() {
      const model = {
        ...this.model,
        email: this.email,
      };

      this.dispatchLoading(
        prefix,
        async () => {
          await this.$api.account.sendPrivacy(model);
        },
        {
          notify: {
            message: this.$t(`${prefix}.notfiy.successful`),
          },
          error: {
            useValidation: true,
            translationKey: 'validation-errors',
          },
        },
      );
    },
  },
};
</script>
