<template>
  <BContainer class="vw-privacy-obtaining mt-4">
    <BOverlay :show="isLoading">
      <BCard>
        <h1>Verarbeitung personenbezogener Daten zum zweck der Personalvermittlung über job3q.de</h1>
        <p>
          Sie haben sich an uns, <b>{{ companyName }}</b> gewendet, damit wir Sie in ein neues Beschäftigungsverhältnis
          vermitteln.
        </p>
        <p>
          Wir nutzen zu diesem Zweck unter anderem die unter https://job3q.app abrufbare Internetplattform "job3q"
          der cu-online GmbH ("Plattform"). Die genaue Funktionsweise der Plattform können Sie den
          "Plattform-AGB" entnehmen.
        </p>
        <p>
          Wir möchten unter Eingabe Ihrer bewerbungsrelevanten Daten ein Bewerberprofil auf der Plattform erstellen. Die
          bewerbungsrelevanten Daten umfassen, soweit sie uns von Ihnen zur Verfügung gestellt werden, bspw. Ihren
          vollständigen Namen, Titel, Geschlecht, Ihre Anschrift und Kontaktdaten, Ihr Geburtsdatum und ggf. Ihren
          Geburtsort, Ihre Staatsangehörigkeit und ggf. aufenthaltsrechtliche Angaben, die Anzahl Ihrer Kinder, Angaben
          zu Ihrer schulischen und beruflichen Ausbildung sowie zu einem eventuellen Studium mitsamt den jeweiligen
          Angaben zu Ort, Schwerpunkten, Dauer, Abschlussart und einschlägigen Nachweisen und Zeugnissen, Informationen
          zu Ihrem beruflichen Werdegang (ggf. also Name und Branche der vorherigen Arbeitgeber, Position, Dauer und
          Tätigkeitsschwerpunkte des Arbeitsverhältnisses, Weiterbildungsmaßnahmen und Kündigungsgrund), Ihren
          Führerschein, ein Lichtbild, berufliche Spezialisierungen und Kenntnisse, Sprachkenntnisse, Informationen zu
          angestrebten beruflichen Positionen einschließlich Ihrer Gehaltsvorstellungen, den von Ihnen angestrebten
          Arbeitsort bzw. die maximal akzeptable Entfernung zwischen Ihrem Wohn- und Arbeitsort oder Ihrer
          Umzugsbereitschaft, Ihre zeitliche Verfügbarkeit bzw. den gewünschten Termin der Aufnahme der neuen Tätigkeit.
          Darüber hinaus möchten wir eine persönliche Einschätzung zu Ihrer Person abgeben, die wir aus persönlichen
          Gesprächen mit Ihnen und/oder der Korrespondenz bzw. vorheriger Zusammenarbeit gewonnen haben, um Ihre
          Vermittlung in ein Beschäftigungsverhältnis zu erleichtern.
        </p>
        <p>
          Andere Plattform-Nutzer (potentielle Arbeitgeber und andere Personalvermittler) können dann Ihr Bewerberprofil
          auf der Plattform zunächst nur in anonymisierter Form sehen. Diesen Plattform-Nutzern werden zunächst nur die
          folgenden Informationen angezeigt: Ihr Geschlecht, ein eventueller Titel, der erste Buchstabe Ihres
          Nachnamens, Ihre Berufsgruppe, vorhandene Spezialisierungen, Ihre letzte Karrierestufe, Ihre zeitliche
          Verfügbarkeit und Ihre Gehaltsvorstellung.
        </p>
        <p>
          Erst mit Freischaltung Ihres Bewerberprofils kann der freischaltende potentielle Arbeitgeber oder
          Personalvermittler Ihr vollständiges Bewerberprofil einsehen. Auf den jeweils einschlägigen
          Freischaltungsmechanismus haben Sie mit dieser Einwilligung Einfluss (siehe nachfolgend zu Ihren
          Wahlmöglichkeiten).
        </p>
        <p>
          Sie können entweder eine Ausschlussliste mit potentiellen Arbeitgebern erstellen, die Ihr Bewerberprofil nicht
          freischalten können sollen bzw. denen Ihr Bewerberprofil nicht von Personalvermittlern vorgestellt werden
          können soll. Potentiellen Arbeitgebern auf Ihrer Ausschlussliste, die selbst die Plattform nutzen, wird Ihr
          Bewerberprofil weder vollständig, noch in anonymisierter Form angezeigt.
        </p>
        <p>
          Alternativ können Sie bestimmen, dass Sie jede Freischaltung Ihres Bewerberprofils im Einzelfall freigeben
          können. In diesem Fall erhalten Sie eine E-Mail, sobald ein potentieller Arbeitgeber oder ein
          Personalvermittler die Freischaltung Ihres Bewerberprofils anfragt. In dieser E-Mail befinden sich sowohl
          Links, über die Sie die Freischaltung Ihres Bewerberprofils selbst vornehmen oder ablehnen können als auch
          Informationen über den anfragenden potentiellen Arbeitgeber oder Personalvermittler.
        </p>
        <p>
          Die Freischaltung Ihres Bewerberprofils hat eine Reservierung durch den freischaltenden potentiellen
          Arbeitgeber oder Personalvermittler zur Folge. In diesem Reservierungszeitraum kann der potentielle
          Arbeitgeber mit Ihnen Kontakt aufnehmen oder der Personalvermittler Ihr Bewerberprofil einem potentiellen
          Arbeitgeber (auch außerhalb der Plattform) vorstellen. Zu diesen Zwecken bitten wir Sie um Ihre Einwilligung.
        </p>
        <p>
          Sie sind nicht verpflichtet, diese Einwilligung abzugeben. Aus datenschutzrechtlicher Sicht dürfen weder wir
          noch die cu-online GmbH Ihre personenbezogenen Daten zu den beschriebenen Zwecken verarbeiten, wenn Sie
          nicht einwilligen.
        </p>
        <p>
          Den genauen Umfang der Datenverarbeitung und weitere Einzelheiten, wie die Ihnen diesbezüglich zustehenden
          Rechte, können Sie unserer Datenschutzerklärung und der
          <a href="/api/v1/content/static/documents/de/datenschutzerklaerung.pdf" target="_blank"
            >Plattform-Datenschutzerklärung</a
          >
          entnehmen.
        </p>

        <h3>Einwilligungserklärung</h3>
        <p>
          Hiermit willige ich,
          <BFormInput class="inline-input" placeholder="Vollständiger Name" v-model="fullName" />
          in
          <BFormInput class="inline-input" placeholder="Wohnort" v-model="locality" />
          ein, dass die <b>{{ companyName }}</b> die Plattform job3q in oben beschriebener Weise
          nutzt, um mich in ein neues Beschäftigungsverhältnis zu vermitteln und erkläre mich mit der dafür
          erforderlichen Verarbeitung meiner personenbezogenen Daten durch die cu-online GmbH und die Nutzer der
          Plattform einverstanden. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
          Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Die Datenschutzhinweise meines
          Personalvermittlers und der cu-online GmbH wurden mir zur Verfügung gestellt.
        </p>
        <p>
          Meine Einwilligungserklärung kann ich jederzeit ohne Angaben von Gründen durch formlose Erklärung gegenüber
          meinem Personalvermittler (per Telefon an: {{ phone }} oder per Post an: {{ postAddress }}) oder gegenüber der
          cu-online GmbH (per E-Mail an widerruf@job3q.de oder per Post an cu-online GmbH, Bahnhofstraße 22, 21423 Winsen (Luhe) widerrufen.
        </p>
        <h4>Bitte wählen Sie, unter den unten aufgeführten Punkten</h4>
        <template v-if="!selections.allowGeneric">
          <p>
            <BFormCheckbox v-model="selections.forbiddenCompanies">
              Ich möchte eine Ausschlussliste potentieller Arbeitgeber angeben, die mein Bewerberprofil auf der
              Plattform nicht freischalten können.
            </BFormCheckbox>
          </p>
          <template v-if="selections.forbiddenCompanies">
            <h6>Ausschlussliste Arbeitgeber</h6>
            <OTagInput tag-variant="flow-row" v-model="forbiddenCompanies" min="1" />
          </template>
        </template>

        <p v-if="!selections.allowGeneric">
          <BFormCheckbox v-model="selections.applicantApproval">
            Ich möchte jede einzelne Freischaltung meines Bewerberprofils auf der Plattform individuell freigeben.
          </BFormCheckbox>
        </p>

        <p v-if="showGenericSelection">
          <BFormCheckbox v-model="selections.allowGeneric">
            Ich habe keine Präferenzen bezüglich potentieller Arbeitgeber und bin damit einverstanden, dass mein
            Bewerberprofil auf der Plattform für jeden anfragenden potentiellen Arbeitgeber oder Personalvermittler
            freigegeben wird.
          </BFormCheckbox>
        </p>
        <p v-if="locality && fullName">
          <i>
            {{ timestamp | dateTime }} /
            <template v-if="locality">{{ locality }}</template>
            <br />
            <template v-if="fullName">{{ fullName }}</template>
          </i>
        </p>

        <div slot="footer" class="text-right">
          <BButton variant="primary" :disabled="canSubmit" @click="onClickSubmit"
            >{{ $t(`${prefix}.submit-label`) }}
          </BButton>
        </div>
      </BCard>
    </BOverlay>
  </BContainer>
</template>
<script>
import { BContainer, BCard, BButton, BFormInput, BFormCheckbox, BOverlay } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateTime } from 'library/components/src/tools/filters/date-time';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import OTagInput from '../../o/input/tags/input';

const prefix = 'vw.privacy-obtaining-accept';

export default {
  mixins: [validationMixin, serviceMixin],
  components: {
    BContainer,
    BCard,
    BButton,
    BFormInput,
    BFormCheckbox,
    OTagInput,
    BOverlay,
  },
  filters: {
    dateTime,
  },
  data() {
    return {
      phone: null,
      postAddress: null,
      companyName: null,
      fullName: null,
      selections: {
        forbiddenCompanies: false,
        applicantApproval: false,
        allowGeneric: false,
      },
      forbiddenCompanies: [],
      locality: null,
      timestamp: new Date(),
    };
  },
  computed: {
    prefix: () => prefix,
    canSubmit() {
      return this.$v.$invalid;
    },
    showGenericSelection() {
      return !(this.selections.applicantApproval || this.selections.forbiddenCompanies);
    },
    access() {
      return this.$route.params.access;
    },
  },
  validations: {
    fullName: { required },
    locality: { required },
  },
  // hooks
  beforeMount() {
    this.fetchPrivacy().catch(error =>
      this.$store.dispatch('error', {
        section: prefix,
        error,
      }),
    );
  },
  methods: {
    async fetchPrivacy() {
      const privacyData = await this.$api.account.getPrivacyData(this.access);
      this.phone = privacyData.phone;
      this.companyName = privacyData.companyName;
      this.postAddress = privacyData.postAddress;
    },
    onClickSubmit() {
      const model = {
        fullName: this.fullName,
        selections: { ...this.selections },
        forbiddenCompanies: this.forbiddenCompanies,
        locality: this.locality,
        timestamp: this.timestamp,
      };

      this.dispatchLoading(
        prefix,
        async () => {
          await this.$api.account.sendPrivacyAccepted(this.access, model);
        },
        {
          notify: {
            message: this.$t(`${prefix}.notfiy.successful`),
          },
          error: {
            useValidation: true,
            translationKey: `${prefix}.validation-errors`,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-input {
  display: inline-block;
  width: auto;
}
</style>
